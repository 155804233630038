
import { Options, Vue } from "vue-class-component";
import Wish from "@/classes/Wish";
import User from "@/classes/User";
import WishCard from "@/components/WishCard.vue";
import UserCard from "@/components/UserCard.vue";
import AddWish from "@/components/AddWish.vue";

@Options({
  components: {
    WishCard,
    UserCard,
    AddWish
  },
})
export default class Home extends Vue {
  public static wishes: Wish[] = [];
  public static users: User[] = [];
  public wishes: Wish[] = [];
  public users: User[] = [];
  public user: User | null = null;
  public name = '';
  created(): void {
    let userId = localStorage.getItem('user');
    this.loadWishes();
    this.loadUsers(userId);
  }
  loadWishes(): void {
    if (Home.wishes.length) {
      this.wishes = Home.wishes;
    } else {
      this.reloadWishes();
    }
  }
  reloadWishes(): void {
    fetch("/api/wish/list")
      .then(response => response.json())
      .then(list => {
        list = list.map((x: any) => Object.assign(new Wish(), x));
        this.wishes = Home.wishes = list;
      });
  }
  loadUsers(userId: string | null): void {
    let promise: Promise<void>;
    if (Home.users.length) {
      promise = Promise.resolve();
      this.users = Home.users;
    } else {
      promise = this.reloadUsers();
    }
    promise.then(() => {
      for (let u of this.users) {
        if (u.id == userId) {
          this.user = u;
        }
      }
    });
  }
  reloadUsers(): Promise<void> {
    return fetch("/api/user/list")
      .then(response => response.json())
      .then(list => {
        Home.users = list;
        this.users = Home.users;
      });
  }
  setUser(user: User | null): void {
    if (user) {
      localStorage.setItem('user', user.id);
    } else {
      localStorage.removeItem('user');
    }
    this.user = user;
  }
  createUser(): void {
    fetch('/api/user', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        name: this.name
      })
    }).then(response => response.json())
      .then(json => {
        this.setUser(json);
        this.name = '';
        this.reloadUsers();
      });
  }
  createWish(): void {
    this.reloadWishes();
  }
  logout(): void {
    this.setUser(null);
  }
}
