<template>
  <section class="user" v-if="user">
    <UserCard :name="user.name" @logout="logout" />
  </section>
  <section v-else class="create-user">
    <h1>Vælg eller opret bruger</h1>
    <ul>
      <li v-for="user of users" v-bind:key="user.id" @click="setUser(user)">{{ user.name }}</li>
    </ul>
    <div class="form-group">
      <input type="text" v-model="name" />
      <button @click="createUser" :disabled="!name">Opret bruger</button>
    </div>
  </section>
  <section class="cards">
    <WishCard v-for="wish of wishes" :key="wish.id" :wish="wish" />
  </section>
  <section class="add" v-if="user">
    <AddWish @wish-created="createWish" />
  </section>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import Wish from "@/classes/Wish";
import User from "@/classes/User";
import WishCard from "@/components/WishCard.vue";
import UserCard from "@/components/UserCard.vue";
import AddWish from "@/components/AddWish.vue";

@Options({
  components: {
    WishCard,
    UserCard,
    AddWish
  },
})
export default class Home extends Vue {
  public static wishes: Wish[] = [];
  public static users: User[] = [];
  public wishes: Wish[] = [];
  public users: User[] = [];
  public user: User | null = null;
  public name = '';
  created(): void {
    let userId = localStorage.getItem('user');
    this.loadWishes();
    this.loadUsers(userId);
  }
  loadWishes(): void {
    if (Home.wishes.length) {
      this.wishes = Home.wishes;
    } else {
      this.reloadWishes();
    }
  }
  reloadWishes(): void {
    fetch("/api/wish/list")
      .then(response => response.json())
      .then(list => {
        list = list.map((x: any) => Object.assign(new Wish(), x));
        this.wishes = Home.wishes = list;
      });
  }
  loadUsers(userId: string | null): void {
    let promise: Promise<void>;
    if (Home.users.length) {
      promise = Promise.resolve();
      this.users = Home.users;
    } else {
      promise = this.reloadUsers();
    }
    promise.then(() => {
      for (let u of this.users) {
        if (u.id == userId) {
          this.user = u;
        }
      }
    });
  }
  reloadUsers(): Promise<void> {
    return fetch("/api/user/list")
      .then(response => response.json())
      .then(list => {
        Home.users = list;
        this.users = Home.users;
      });
  }
  setUser(user: User | null): void {
    if (user) {
      localStorage.setItem('user', user.id);
    } else {
      localStorage.removeItem('user');
    }
    this.user = user;
  }
  createUser(): void {
    fetch('/api/user', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        name: this.name
      })
    }).then(response => response.json())
      .then(json => {
        this.setUser(json);
        this.name = '';
        this.reloadUsers();
      });
  }
  createWish(): void {
    this.reloadWishes();
  }
  logout(): void {
    this.setUser(null);
  }
}
</script>

<style lang="scss">
  .cards {
    display: grid;
    justify-content: center;
    grid-gap: 5vw;
    margin: 30px 0;
  }

  @media (min-width: 310px) {
    .cards {
      grid-template-columns: 300px;
      grid-gap: 10px;
    }
  }

  @media (min-width: 640px) {
    .cards {
      grid-template-columns: repeat(2, 300px);
      grid-gap: 20px;
    }
    .create-user .form-group {
      display: grid;
      grid-template-columns: auto auto;
      grid-gap: 20px;
      align-items: center;
      margin: 0 auto;

      input {
        height: 3em;
        margin: 0;
        justify-self: right;
      }

      button {
        margin: 0;
        justify-self: left;
      }
    }
  }

  @media (min-width: 990px) {
    .cards {
      grid-template-columns: repeat(3, 300px);
      grid-gap: 30px;
    }
  }

  section.create-user input {
    width: 300px;
    display: block;
    margin: 0 auto;
  }

  section ul {
    padding: 0;
    width: 300px;
    margin: 0 auto 30px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
    font-size: 1.2em;

    li {
      list-style-type: none;
      cursor: pointer;
      padding: 7px 4px;

      &:hover {
        font-weight: bold;
        background: #f1f1f1;
      }
    }
  }
</style>
