<template>
  <div id="nav">
    <router-link to="/">Gave idéer</router-link> |
    <router-link to="/indbydelse">Indbydelse</router-link>
  </div>
  <main>
    <router-view />
  </main>
</template>

<style lang="scss">
:root {
  --background-color: hsl(0deg, 0%, 99%);
  --light-color: hsl(197deg, 100%, 70%);
  --dark-color: hsl(197deg, 100%, 40%);
  --gradient-color: linear-gradient(0deg, var(--dark-color), var(--light-color));
  --light-important-color: hsl(0deg, 60%, 70%);
  --dark-important-color: hsl(0deg, 60%, 50%);
  --important-gradient: linear-gradient(0deg, var(--dark-important-color), var(--light-important-color));
  --light-disabled-color: hsl(0deg, 0%, 70%);
  --dark-disabled-color: hsl(0deg, 0%, 50%);
  --disabled-gradient: linear-gradient(0deg, var(--dark-disabled-color), var(--light-disabled-color));
  --text-color: hsl(210deg, 20%, 20%);
}

html,body {
  margin: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: var(--text-color);
}

ul {
  padding: 0;

  li {
    margin: 0;
    list-style-type: none;
  }
}

button {
  background: var(--gradient-color);
  border: 0;
  box-sizing: border-box;
  padding: 5px 8px;
  border-radius: 5px;
  color: white;
  font-weight: bold;
  font-size: large;

  &:disabled {
    background: var(--disabled-gradient);
  }
}

a {
  text-decoration: none;
  color: var(--dark-color);
}

.form-group {
  text-align: left;
  margin: 30px 0;

  &.inline {
    display: grid;
    grid-template-columns: 3fr 2fr;

    input {
      display: inline-block;
    }
    button {
      margin: 0;
    }
  }

  label {
    display: block;
    margin-bottom: 10px;
    font-size: large;
    color: hsl(0deg, 0%, 20%);
  }
  input,
  textarea {
    padding: 3px 5px;
    font-size: large;
    display: block;
    width: 100%;
    box-sizing: border-box;
  }

  textarea {
    height: 200px;
  }
}

button {
  margin: 10px auto 30px;
  display: block;
  font-size: xx-large;
  cursor: pointer;

  &:hover {
    --light-color: hsl(197deg, 100%, 80%);
    --dark-color: hsl(197deg, 100%, 50%);
    --gradient-color: linear-gradient(0deg, var(--dark-color), var(--light-color));
  }
}

main {
  width: min(90vw, 980px);
  margin: 0 auto;
}

#nav {
  padding: 30px;
  font-size: xx-large;
  background: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  position: sticky;
  top: 0;
  z-index: 100;

  a {
    font-weight: bold;

    &.router-link-exact-active {
      color: var(--light-color);
    }
  }
}
</style>
