
import { Options, Vue } from 'vue-class-component'

type DialogType = "hidden" | "unknown" | "web" | "manual";

@Options({
    emits: ['wishCreated']
})
export default class AddWish extends Vue {
    public title = "";
    public description = "";
    public url = "";
    public image = "";
    public dialogType: DialogType = "hidden";

    createWish(event: Event): void {
        const form = event.target as HTMLFormElement;
        const formData = new FormData(form);
        const userId = this.userId;
        if (!userId) {
            throw new Error('Missing user');
        }
        formData.append('user', userId);
        fetch(form.action, {
            method: form.method,
            body: formData
        })
        .then(response => response.json())
        .then((json) => {
            this.title = '';
            this.description = '';
            this.url = '';
            this.image = '';
            this.dialogType = 'hidden';
            this.$emit('wishCreated', json);
        });
    }

    get userId(): string | null {
        return localStorage.getItem('user');
    }

    addImage(): void {
        console.log();
    }

    closeDialog(): void {
        this.dialogType = 'hidden';
    }

    loadWish(): void {
        fetch('/api/wish/load', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                url: this.url
            })
        })
        .then(response => response.json())
        .then(json => {
            if (json) {
                this.title = json.title;
                this.description = json.description;
                this.url = json.url;
                this.image = json.image;
            } else {
                this.title = '?';
            }
            this.dialogType = 'manual';
        });
    }
}
