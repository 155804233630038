<template>
    <h3>Hej {{ name }}</h3>
    <button @click="logout">Log ud</button>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'

@Options({
    props: {
        name: String
    },
    emits: ['logout']
})
export default class UserCard extends Vue {
    public name!: string;
    logout(): void {
        this.$emit('logout');
    }
}
</script>

<style lang="scss" scoped>
    h3 {
        text-align: center;
    }
</style>