<template>
    <div class="wish card">
        <router-link :to="`/detaljer/${wish.id}`">
            <h3>{{ wish.title }}</h3>
            <div class="description">{{ wish.description }}</div>
            <div class="image">
                <img v-bind:src="image" />
            </div>
            <div class="meta">
                <div class="comments">{{ commentText }}</div>
                <div class="reservertions">{{ reservertionText }}</div>
            </div>
        </router-link>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import Wish from "@/classes/Wish";

@Options({
    props: {
        wish: Wish
    }
})
export default class WishCard extends Vue {
    public wish!: Wish;
    private milaImages = 5;
    private static usedImages: number[] = [];
    public get image(): string {
        return this.wish.image ? `/api/images/${this.wish.image}` : this.randomImage;
    }
    public get randomImage(): string {
        if (WishCard.usedImages.length == this.milaImages) {
            WishCard.usedImages = [];
        }
        let index;
        do {
            index = Math.floor(Math.random() * this.milaImages);
        } while (WishCard.usedImages.indexOf(index) != -1);
        WishCard.usedImages.push(index);
        return `/api/images/mila${index}.jpg`;
    }
    private numberText(num: number, text: string) {
        const result: string[] = [];
        result.push(num ? num.toString() : 'Ingen');
        result.push(num == 1 ? text : text + 'er');
        return result.join(' ');
    }
    public get commentText(): string {
        return this.numberText(this.wish.commentCount, 'kommentar');
    }
    public get reservertionText(): string {
        return this.numberText(this.wish.reservertions, 'reservertion');
    }
}
</script>

<style lang="scss">
    .cards .card {
        box-shadow: 0 0 7px rgba(0, 0, 0, 0.6);
        background: var(--background-color);

        img {
            width: 300px;
            height: 300px;
            object-fit: contain;
            display: block;

            &[src*="/mila"] {
                filter: sepia(50%);
            }
        }

        .description {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            margin: 10px;
        }

        .meta {
            display: grid;
            margin: 10px;
            grid-template-columns: auto auto;
            .comments {

            }
            .reservertions {

            }
        }
    }
</style>