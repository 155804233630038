<template>
    <button @click="dialogType='unknown'" class="add-wish">Tilføj gave</button>
    <dialog ref="dialogUnknown" v-if="dialogType=='unknown'" open @click.self="closeDialog">
        <section>
            <button @click="dialogType='web'">☁ Web</button>
            <button @click="dialogType='manual'">✍ Manuel</button>
        </section>
    </dialog>
    <dialog ref="dialogAuto" v-if="dialogType=='web'" open @click.self="closeDialog">
        <section>
            <div class="form-group">
                <label>Url</label>
                <input type="text" v-model="url" />
            </div>
            <div class="form-group">
                <button @click="loadWish()">Hent data</button>
            </div>
        </section>
    </dialog>
    <dialog ref="dialogManual" v-if="dialogType=='manual'" open @click.self="closeDialog">
        <section>
            <form method="post" action="/api/wish" enctype="multipart/form-data" @submit.prevent="createWish($event)">
                <div class="form-group">
                    <label>Titel</label>
                    <input type="text" name="title" v-model="title" />
                </div>
                <div class="form-group">
                    <label>Beskrivelse</label>
                    <textarea v-model="description" name="description"></textarea>
                </div>
                <div class="form-group" v-if="image">
                    <label>Billede</label>
                    <img :src="image" />
                    <input type="hidden" name="image" v-model="image" />
                </div>
                <div class="form-group" v-else>
                    <label>Billede</label>
                    <input type="file" name="file" />
                </div>
                <div class="form-group">
                    <button type="submit">Tilføj</button>
                </div>
            </form>
        </section>
    </dialog>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'

type DialogType = "hidden" | "unknown" | "web" | "manual";

@Options({
    emits: ['wishCreated']
})
export default class AddWish extends Vue {
    public title = "";
    public description = "";
    public url = "";
    public image = "";
    public dialogType: DialogType = "hidden";

    createWish(event: Event): void {
        const form = event.target as HTMLFormElement;
        const formData = new FormData(form);
        const userId = this.userId;
        if (!userId) {
            throw new Error('Missing user');
        }
        formData.append('user', userId);
        fetch(form.action, {
            method: form.method,
            body: formData
        })
        .then(response => response.json())
        .then((json) => {
            this.title = '';
            this.description = '';
            this.url = '';
            this.image = '';
            this.dialogType = 'hidden';
            this.$emit('wishCreated', json);
        });
    }

    get userId(): string | null {
        return localStorage.getItem('user');
    }

    addImage(): void {
        console.log();
    }

    closeDialog(): void {
        this.dialogType = 'hidden';
    }

    loadWish(): void {
        fetch('/api/wish/load', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                url: this.url
            })
        })
        .then(response => response.json())
        .then(json => {
            if (json) {
                this.title = json.title;
                this.description = json.description;
                this.url = json.url;
                this.image = json.image;
            } else {
                this.title = '?';
            }
            this.dialogType = 'manual';
        });
    }
}
</script>

<style lang="scss">
    dialog[open] {
        display: block;
    }
    dialog {
        display: none;
        position: fixed;
        padding: 0;
        border: 0;
        margin: 0;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: 10;
        backdrop-filter: blur(5px);
        background: rgba(128, 128, 128, 0.5);
        overflow: auto;
        z-index: 100;

        section {
            width: clamp(400px, 50vw, 600px);
            margin: 40px auto;
            background: white;
            box-shadow: 0 0 20px rgba(0, 0, 0, 0.6);
            padding: 20px;
        }
    }
</style>