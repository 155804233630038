
import { Vue, Options } from 'vue-class-component'

@Options({
    props: {
        name: String
    },
    emits: ['logout']
})
export default class UserCard extends Vue {
    public name!: string;
    logout(): void {
        this.$emit('logout');
    }
}
